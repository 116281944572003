import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import HubspotForm from 'react-hubspot-form'

export default () => {

  const PORTAL_ID = '7696348';
  const FORM_ID = 'c27be279-6e79-4e4f-bde0-8fc75fef3460';

  return (
    <Layout>
      <SEO title="Home" />
      <section className="contact-banner background-overlay">
        <h1 className="banner-title">CONTACT US</h1>
      </section>

      <section className="contact-content">
        <div className="img-row">
          <div className="col">

            <HubspotForm
              portalId={PORTAL_ID}
              formId={FORM_ID}
              loading={<div>Loading...</div>}
            />
          </div>
          <div className="col">
            <h2>CONTACT INFO</h2>
            <p><strong>Address</strong>​</p>
            <p>
              Cordial Radar Limited,<br />
            2A Cheung Hing Building,<br />
            12P Smithfield,<br />
            Kennedy Town,<br />
            Hong Kong S.A.R.
          </p>
          </div>
        </div>
      </section>
    </Layout>
  )
};

